<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import logo from "../../../core/assets/logo-vertiflex-white.svg";
	import linkedin from "../../../core/assets/icons/li.svg?raw";
	import youtube from "../../../core/assets/icons/youtube.svg?raw";
	import facebook from "../../../core/assets/icons/fb.svg?raw";
	import instagram from "../../../core/assets/icons/ig.svg?raw";
	import { appName } from "../../../core/schema/appName";
	import { getTranslate } from "../utils/getTranslate";
	import ContactForm from "./Contact/ContactForm.svelte";
	import translations from "./Footer.translations.json";
	import Address from "./Address.svelte";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";

	const translate = getTranslate(translations);

	const contactDetails: Record<string, string>[] = [
		{
			name: translate("phone"),
			value: "+420 554 818 554",
			urlSlug: "tel:00420554818554",
		},
		{
			name: translate("email"),
			value: "info@vertiflex.eu",
			urlSlug: "mailto:info@vertiflex.eu",
		},
		{
			name: translate("sales"),
			value: "sales@vertiflex.eu",
			urlSlug: "mailto:sales@vertiflex.eu",
		},
		{
			name: translate("service"),
			value: "service@vertiflex.eu",
			urlSlug: "mailto:service@vertiflex.eu",
		},
	];

	const socials: Record<string, string>[] = [
		{
			title: "LinkedIn",
			urlSlug: "https://www.linkedin.com/company/vertiflexeu/posts/",
			icon: linkedin,
		},
		{
			title: "YouTube",
			urlSlug: "https://www.youtube.com/@vertiflex8003",
			icon: youtube,
		},
		{
			title: "Facebook",
			urlSlug: "https://www.facebook.com/VertiFlexEU",
			icon: facebook,
		},
		{
			title: "Instagram",
			urlSlug: "https://www.instagram.com/vertiflex.eu/",
			icon: instagram,
		},
	];
</script>

<footer class="text-xs md:container md:mb-4">
	<div class="bg-primary mt-12 flex flex-col px-4 pb-12 pt-6 sm:p-8 md:rounded-lg lg:mt-24">
		<div class="mb-16 flex grid-cols-2 flex-col gap-6 lg:mb-32 lg:grid" id="contact-form">
			<p
				class="font-spaceGrotesk mx-auto max-w-[22rem] text-center text-xl leading-[1.1] text-white max-lg:mt-7 lg:mx-[initial] lg:text-left lg:text-xl"
			>
				{translate("title")}
			</p>
			<ContactForm />
		</div>
		<div
			class="flex grid-cols-8 flex-col items-center justify-center gap-8 text-center lg:grid lg:items-start lg:text-left"
		>
			<a href="/" class="lg:col-span-2">
				<StaticPicture
					alt="Logo {appName}"
					image={logo}
					class="h-6 w-auto lg:h-8"
					imgClass="w-fit h-full"
					loading="eager"
					width={227}
					height={25}
				/>
			</a>
			<Address class="col-span-2" />
			<div class="col-span-3">
				<table>
					{#each contactDetails as detail}
						<tr>
							<td class="pr-4 text-left text-white">{detail.name}:</td>
							<td>
								<Link
									class="font-semibold text-white decoration-transparent hover:decoration-white"
									href={detail.urlSlug}
								>
									{nbspify(detail.value)}
								</Link>
							</td>
						</tr>
					{/each}
				</table>
			</div>
			<div class="flex items-start justify-end justify-items-end gap-4">
				{#each socials as social}
					<Link
						aria-label={social.title}
						href={social.urlSlug}
						icon={social.icon}
						iconClass="h-5 w-5 text-white duration-500 transition-[transform] ease-in-out hover:scale-[1.2]"
						target="_blank"
					/>
				{/each}
			</div>
		</div>
	</div>
</footer>
